.homepage-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.header {
  height: 100%;
  min-width: 80%;
  background-image: url("../../assets/img/home.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.header-content {
  flex-direction: row;
  justify-content: center;
  display: flex;
  position: relative;
  height: 1000px;
}

.contact-container {
  padding-top: 150px;
}

#spline {
  max-width: 100%;
  max-height: 90%;
  position: absolute;
}

@media (min-width: 1025px) {
  .header-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
  }
  .header-right {
    display: flex;
    flex: 1;
    max-width: 50%;
  }

  #text {
    color: white;
    font-size: 2.5rem;
    padding-left: 40px;
  }
}

@media (min-width: 280px) {
  .header-left {
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
  }

  .header-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  #text {
    color: white;
  }
}
