.navbar-container {
  display: flex;
  justify-content: center;
}

/* .navbar-header {
  display: flex;
  padding: 3rem;
  justify-content: flex-start;
} */
/* 
.left-half {
  flex: 1;
} */

/* .right-half {
  flex: 1;
} */

ul {
  color: black;
  list-style: none;
  display: inline-flex;
}

li {
  padding: 3rem;
  padding-left: 2rem;
  font-size: 18px;
}

.nav-buttons {
  display: flex;
}

.nav-buttons a {
  text-decoration: none;
  font-weight: bolder;
  color: white;
}

.nav-buttons a:hover {
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 20%;
}
.nav-buttons {
  display: flex;
  padding: 0.7rem;
  justify-content: flex-end;
}
