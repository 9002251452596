.p-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 3px;
  background-color: white;
}

.project-tiles {
  padding: 50px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.projectItems-container {
  display: flex;
  flex-direction: column;
}

.project-banner {
  font-size: 40px;
  font-weight: bolder;
}

#folder1 {
  padding-right: 200px;
}

#folder2 {
}

a {
  text-decoration: none;
  color: gray;
}

.folder-icons {
  display: inline-flex;
  justify-content: center;
}
