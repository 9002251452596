.contact {
  height: 55vh;
  position: relative;
}

.contact-bg {
  width: 20px;
  height: 125%;
  background-color: rgb(255, 187, 0);
  position: absolute;
}

.contact-wrapper {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.contact-left {
  /* padding-right: 300px; */
  justify-content: center;
  flex: 1;
}

.contact-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 220px 120px 0px 120px;
}

.contact-title {
  font-size: 5.5rem;
  width: 100%;
  text-align: center;
}

.contact-info-item {
  display: flex;
  align-items: center;
  margin: 40px 0px;
  font-weight: 300;
  width: 70%;
}

.contact-desc {
  font-weight: 400;
}

input {
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 2px solid black;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}

textarea {
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}

button {
  border: none;
  padding: 15px;
  background-color: black;
  color: white;
  font-weight: bold;
  cursor: pointer;
  /* border-radius: 50%; */
  /* height: 70px; */
}

#contact-icon {
  padding-left: 70px;
}
