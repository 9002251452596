.projects {
  display: flex;
  flex-direction: column;
}

.projects-container {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap-reverse;
  padding-top: 40px;
}

.left-half {
  flex: 1;
}

.right-half {
  flex: 2;
}

.projects-banner h2 {
  height: 100%;
  font-size: 6.5rem;
  padding: 40px;
}

.projects-info {
  padding: 7rem;
}

/* .projects-info p {
  padding: 10rem;
} */

.project-links a {
  text-decoration: none;
  color: black;
}
