.items-container {
  height: 40%;
  width: 30%;
  margin: 20px 10px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 10px 10px; /* ROUNDED CORNERS*/
  overflow: hidden;
}

.p-circle {
  display: inline-block;
}

.card-browser {
  display: flex;
  position: sticky;
  z-index: 2;
  height: 20px;
  background-color: rgb(243, 242, 242);
}

.p-img {
  width: 100%;
  transition: all 10s ease;
}

.p-img:hover {
  transform: translateY(-100%);
}

.name-path {
  font-size: 70%;
  width: 100%;
  margin-top: -10px;
  overflow: hidden;
}

#Coming-Soon {
  width: 20%;
  height: 25%;
  margin-top: 80px;
}
